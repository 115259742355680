import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { Close as CloseIcon } from "@styled-icons/material/Close"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { RichText } from "../api/contentful"
import Map from "../components/map"
import {
  Link,
  Section,
  Pagehead,
  PageTitle,
  Breadcrumbs,
} from "../components/shared"

const outlineWidth = `2px`

const ToggleButton = styled.button.attrs(() => ({ type: "button" }))`
  display: none;
  position: relative;
  margin-top: 0.6em;
  width: 16em;
  font-size: 1.1rem;
  font-weight: bold;
  border: ${outlineWidth} solid transparent;
  border-radius: 0;
  outline: none;
  cursor: pointer;

  &::before {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    outline: ${outlineWidth} solid;
  }

  &:focus {
    &::before {
      background-color: ${props =>
        props.$isActive
          ? undefined
          : props.theme.partnersToggleHoverBackgroundColor};
    }
  }

  &::after {
    display: block;
    content: " ";
    position: absolute;
    top: -${outlineWidth};
    left: calc(
      ${props => (props.$firstActive ? `0px` : `50%`)} - ${outlineWidth}
    );
    bottom: -${outlineWidth};
    right: calc(
      ${props => (props.$firstActive ? `50%` : `0px`)} - ${outlineWidth}
    );
    z-index: 2;
    background-color: ${props =>
      props.theme.partnersToggleActiveBackgroundColor};
    transition: left 0.2s, right 0.2s;
  }

  @media only screen and (max-width: 666px) {
    display: block;
  }
`

const ToggleLabel = styled.div`
  display: inline-block;
  position: relative;
  width: 50%;
  z-index: 3;
  color: ${props =>
    props.$isActive ? props.theme.partnersToggleActiveColor : undefined};
  transition: color 0.2s;
`

const Toggle = ({ showList, setShowList }) => (
  <ToggleButton
    $firstActive={!showList}
    onClick={() => {
      setShowList(showList => !showList)
      document.activeElement.blur()
    }}
  >
    <ToggleLabel $isActive={!showList}>Karte</ToggleLabel>
    <ToggleLabel $isActive={showList}>Liste</ToggleLabel>
  </ToggleButton>
)

const Columns = styled.div`
  display: flex;
  height: 50em;
  max-height: calc(100vh - ${props => props.theme.headerHeight} - 4em);

  @media only screen and (min-width: 960px) {
    max-height: calc(100vh - ${props => props.theme.headerHeightWide} - 4em);
  }
`

const MapColumn = styled.div`
  flex: 2;

  @media only screen and (max-width: 666px) {
    display: ${props => (props.$show ? undefined : `none`)};
  }
`

const List = styled.ul`
  flex: 1;
  position: relative;
  margin: 0;
  padding: 0 1em;
  overflow: auto;
  list-style: none;

  /* Scrolling shadows, cf. https://lea.verou.me/2012/04/background-attachment-local/ */
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

  @media only screen and (max-width: 666px) {
    display: ${props => (props.$show ? undefined : `none`)};
    padding: 0;
  }
`

const ListItem = styled.li`
  position: relative;
  border-width: 2px 0 0 0;
  border-style: solid;
`

const SingleItem = styled(ListItem)`
  position: absolute;
  left: 1em;
  right: 1em;
  z-index: 2;
  border-bottom-width: 2px;
  background-color: white;

  @media only screen and (min-width: 666px) {
    display: none;
  }
`

const wrapperCss = css`
  display: block;
  padding: 1em 0 0.8em 0;
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.4;
`

const Button = styled.button.attrs(() => ({ type: "button" }))`
  ${wrapperCss}
  text-align: left;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  border: none;
`

const PlaceDetailWrapper = styled.div`
  ${wrapperCss}
`

const CloseButton = styled.button.attrs(() => ({
  type: "button",
}))`
  position: absolute;
  padding: 0;
  border: none;
  right: 0.2em;
  cursor: pointer;
`

const CloseButtonIcon = styled(CloseIcon)`
  height: 1.2em;
`

const PartnerName = styled.div`
  font-weight: ${props => (props.$isSelected ? "bold" : "normal")};
`

const PartnerAddress = styled.div``

const LinkWrapper = styled.div``

const PlaceDetail = ({
  name,
  place,
  postalCode,
  url,
  setSelectedPartnerId,
}) => (
  <PlaceDetailWrapper>
    <CloseButton
      onClick={event => {
        setSelectedPartnerId(null)
        event.stopPropagation()
      }}
    >
      <CloseButtonIcon />
    </CloseButton>

    <PartnerName $isSelected>{name}</PartnerName>
    <PartnerAddress>
      {place}, {postalCode}
    </PartnerAddress>
    <LinkWrapper>
      <Link to={url} target="_blank">
        {url.replace(/^https?:\/\//, "").replace(/\/$/, "")}
      </Link>
    </LinkWrapper>
  </PlaceDetailWrapper>
)

const Partners = ({ data }) => {
  const pageData = data.contentfulPartnersPage

  const partners = React.useMemo(
    () =>
      data.allContentfulPartner.edges
        .map(edge => edge.node)
        .sort((a, b) =>
          (a.sortingName || a.name).localeCompare(b.sortingName || b.name)
        ),
    [data]
  )

  // TODO: better solution for multiple refs?
  // eslint-disable-next-line
  const partnerRefs = partners.map(() => React.useRef())

  const [selectedPartnerId, setSelectedPartnerId] = React.useState(null)

  const selectedPartner = React.useMemo(
    () => partners.find(partner => partner.name === selectedPartnerId),
    [partners, selectedPartnerId]
  )

  React.useEffect(() => {
    if (!selectedPartner) {
      return
    }

    const index = partners.indexOf(selectedPartner)
    if (index === -1) {
      throw Error("Selected partner not in list of partners")
    }

    const ref = partnerRefs[index]
    if (!ref) {
      throw Error("No ref for selected partner")
    }

    const element = ref.current
    if (!element) {
      throw Error("No element for selected partner")
    }

    // TODO:
    element.parentNode.scrollTop = element.offsetTop - 100
    // element.scrollIntoView({ behavior: "smooth" })
  }, [partners, selectedPartner, partnerRefs])

  const [showList, setShowList] = React.useState(false)

  return (
    <Layout>
      <SEO title={pageData.title} description={pageData.metaDescription} />
      <Pagehead>
        <PageTitle>
          <RichText node={pageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <Toggle showList={showList} setShowList={setShowList} />
        <Breadcrumbs nodes={[{ title: "Partner" }]} />
      </Pagehead>
      <Section>
        {selectedPartner && !showList && (
          <SingleItem as="div">
            <PlaceDetail
              {...selectedPartner}
              setSelectedPartnerId={setSelectedPartnerId}
            />
          </SingleItem>
        )}
        <Columns>
          <MapColumn $show={!showList}>
            <Map
              places={partners}
              selectedPlace={selectedPartner}
              selectPlace={name => setSelectedPartnerId(name)}
            />
          </MapColumn>
          <List $show={showList}>
            {partners.map((partner, index) => {
              const isSelected = partner.name === selectedPartnerId

              if (isSelected) {
                return (
                  <ListItem ref={partnerRefs[index]} key={partner.name}>
                    <PlaceDetail
                      {...partner}
                      setSelectedPartnerId={setSelectedPartnerId}
                    />
                  </ListItem>
                )
              }

              return (
                <ListItem ref={partnerRefs[index]} key={partner.name}>
                  <Button
                    onClick={() => {
                      setSelectedPartnerId(partner.name)
                    }}
                  >
                    <PartnerName>{partner.name}</PartnerName>
                  </Button>
                </ListItem>
              )
            })}
          </List>
        </Columns>
      </Section>
      <Contact />
    </Layout>
  )
}

export default Partners

export const query = graphql`
  query PartnerPageQuery {
    contentfulPartnersPage {
      title
      metaDescription
      pageTitle {
        json
      }
    }

    allContentfulPartner {
      edges {
        node {
          name
          sortingName
          url
          place
          postalCode
          location {
            lon
            lat
          }
        }
      }
    }
  }
`
