import React from "react"
import styled, { css } from "styled-components"
import ReactMapGL, { Marker, FlyToInterpolator } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import { ReactComponent as MarkerSvg } from "../images/marker.svg"

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.$mapHasLoaded ? 1 : 0)};
  transition: opacity 200ms;

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.mapOverlayBackgroundColor};
    pointer-events: ${props =>
      props.$showScrollZoomInstructions ? "unset" : "none"};
    cursor: ${props =>
      props.$showScrollZoomInstructions ? "pointer" : "unset"};
    opacity: ${props => (props.$showScrollZoomInstructions ? 0.05 : 0)};
    transition: opacity 400ms;
  }
}
`

const ScrollZoomInstructions = styled.div`
  position: absolute;
  padding: 0.2em 0.4em;
  font-size: 1.1rem;
  color: ${props => props.theme.callToActionVariantColor};
  background-color: ${props => props.theme.callToActionVariantBackgroundColor};
  pointer-events: none;
  cursor: ${props => (props.$showScrollZoomInstructions ? "pointer" : "unset")};
  opacity: ${props => (props.$showScrollZoomInstructions ? 1 : 0)};
  transition: opacity 400ms;
`

const markerCss = css`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.4));
`

const UnselectedMarkerIcon = styled(MarkerSvg)`
  ${markerCss}
  color: ${props => props.theme.mapMarkerColor} !important;
`

const SelectedMarkerIcon = styled(MarkerSvg)`
  ${markerCss}
  pointer-events: none;
  color: ${props => props.theme.mapSelectedMarkerColor} !important;
`

const PlaceNameWrapper = styled.div`
  position: absolute;
  min-width: 10em;
  pointer-events: none;
`

const PlaceName = styled.span`
  display: inline-block;
  position: absolute;
  top: 0.6em;
  padding: 0.2em;
  transform: translateX(-50%);
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.6);
`

const [minLatitude, minLongitude] = [46, 3]
const [maxLatitude, maxLongitude] = [55, 16]

const centerLat = (minLatitude + maxLatitude) / 2
const centerLng = (minLongitude + maxLongitude) / 2
const minZoom = 5
const maxZoom = 9
const initialZoom = minZoom

const clamp = (value, min, max) => Math.max(min, Math.min(max, value))

const limitToBounds = viewport => {
  const latitude = clamp(viewport.latitude, minLatitude, maxLatitude)
  const longitude = clamp(viewport.longitude, minLongitude, maxLongitude)
  return {
    ...viewport,
    latitude,
    longitude,
  }
}

const viewportConstants = {
  minZoom: minZoom,
  maxZoom: maxZoom,
}

const initialViewport = {
  latitude: centerLat,
  longitude: centerLng,
  zoom: initialZoom,
}

const Map = ({ places, selectedPlace, selectPlace }) => {
  const [viewport, _setViewport] = React.useState({
    ...viewportConstants,
    ...initialViewport,
  })

  const updateViewport = React.useCallback(
    updater => {
      _setViewport(viewport => {
        let requestedViewport
        if (typeof updater === "function") {
          requestedViewport = updater(viewport)
        } else {
          requestedViewport = updater
        }
        return limitToBounds(requestedViewport)
      })
    },
    [_setViewport]
  )

  React.useEffect(() => {
    if (!selectedPlace) {
      updateViewport(viewport => ({ ...viewport, ...initialViewport }))
      return
    }

    updateViewport(viewport => ({
      ...viewport,
      longitude: selectedPlace.location.lon,
      latitude: selectedPlace.location.lat,
      zoom: maxZoom,
      transitionInterpolator: new FlyToInterpolator({ screenSpeed: 2 }),
      transitionDuration: 1000,
      // transitionEasing: cubicOut,
    }))
  }, [updateViewport, selectedPlace])

  const [scrollZoomIsEnabled, setScrollZoomIsEnabled] = React.useState(false)
  const enableScrollZoom = React.useCallback(() => {
    setScrollZoomIsEnabled(true)
  }, [])
  const disableScrollZoom = React.useCallback(() => {
    setScrollZoomIsEnabled(false)
  }, [])

  const mapContainer = React.useRef()

  const handleWindowWheel = React.useCallback(
    event => {
      const scrolledElement = event.target
      const mapContainerParent = mapContainer.current?.parentNode
      if (!mapContainerParent) {
        return
      }
      const scrollWasOutsideMap = !mapContainerParent.contains(scrolledElement)
      if (scrollWasOutsideMap) {
        disableScrollZoom()
      }
    },
    [disableScrollZoom]
  )

  React.useEffect(() => {
    window.addEventListener("wheel", handleWindowWheel)
    return () => {
      window.removeEventListener("wheel", handleWindowWheel)
    }
  }, [handleWindowWheel])

  const [mapHasBeenScrolled, setMapHasBeenScrolled] = React.useState(false)

  React.useEffect(() => {
    let timeout
    if (mapHasBeenScrolled) {
      timeout = setTimeout(() => {
        setMapHasBeenScrolled(false)
      }, 2000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [mapHasBeenScrolled])

  const showScrollZoomInstructions = !scrollZoomIsEnabled && mapHasBeenScrolled

  const [mapHasLoaded, setMapHasLoaded] = React.useState(false)

  const placesNorthToSouth = React.useMemo(
    () => places.sort((a, b) => b.location.lat - a.location.lat),
    [places]
  )

  return (
    <MapWrapper
      $mapHasLoaded={mapHasLoaded}
      $showScrollZoomInstructions={showScrollZoomInstructions}
      onClick={enableScrollZoom}
      onWheel={event => {
        event.stopPropagation()
      }}
      onMouseLeave={() => {
        setMapHasBeenScrolled(false)
      }}
    >
      <ReactMapGL
        {...viewport}
        onLoad={event => {
          const map = event.target
          mapContainer.current = map.getContainer()
          setMapHasLoaded(true)
        }}
        onViewportChange={viewport => updateViewport(viewport)}
        mapStyle="mapbox://styles/ansgarprause/ck9pv03s210ja1imqysx7ey3x"
        width="100%"
        height="100%"
        scrollZoom={scrollZoomIsEnabled}
        onWheel={event => {
          if (!scrollZoomIsEnabled) {
            setMapHasBeenScrolled(true)
            event.stopPropagation()
          }
        }}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_ACCESS_TOKEN}
      >
        {placesNorthToSouth
          .filter(place => place !== selectedPlace)
          .map(({ name, location }) => (
            <Marker longitude={location.lon} latitude={location.lat} key={name}>
              {/* TODO: alt */}
              <UnselectedMarkerIcon
                alt=""
                onClick={() => {
                  selectPlace(name)
                }}
              />
            </Marker>
          ))}
        {selectedPlace &&
          [selectedPlace].map(({ name, location }) => (
            <Marker longitude={location.lon} latitude={location.lat} key={name}>
              {/* TODO: alt */}
              <SelectedMarkerIcon
                alt=""
                onClick={() => {
                  selectPlace(name)
                }}
              />
              <PlaceNameWrapper>
                <PlaceName>{name}</PlaceName>
              </PlaceNameWrapper>
            </Marker>
          ))}
      </ReactMapGL>
      <ScrollZoomInstructions
        $showScrollZoomInstructions={showScrollZoomInstructions}
      >
        Bitte klicken Sie, um mit dem Mausrad zu zoomen
      </ScrollZoomInstructions>
    </MapWrapper>
  )
}

export default Map
